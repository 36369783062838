.topbar__user__login .sign-up-link {
  display: none;
}

.button--SomEnergia {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  text-align: center;
}
.button--SomEnergia .button--social {
  position: initial;
}
.button--SomEnergia .button--social__icon {
  position: initial;
  display: block;
}

.button-login {
  text-align: center;
  text-decoration: none;
  margin: 0 0 1rem 0;
  display: block;
  color: white;
  width: 100%;
  background-color: var(--secondary);
  -webkit-appearance: none;
  border: 10px solid transparent;
  cursor: pointer;
}
.button-login:hover {
  background-color: rgba(var(--secondary-rgb), 0.7);
}

.button-login:-moz-focusring {
  outline: none;
}

.login__box {
  display: none;
}